import { ActivityStatus } from '_config/enums/activity-status.enum'
import { AuctionLotActivityStatus } from '_config/enums/auction-lot-activity-status.enum'
import { AuctionStatus } from '_config/enums/auction-status.enum'

export const auctionStatusMapping = new Map()
auctionStatusMapping.set(AuctionStatus.Ready, 'Starting Soon')
auctionStatusMapping.set(AuctionStatus.InProgress, 'In Progress')
auctionStatusMapping.set(AuctionStatus.Paused, 'In Progress')
auctionStatusMapping.set(AuctionStatus.Finished, 'Finished')
auctionStatusMapping.set(AuctionStatus.Closed, 'Finished')
auctionStatusMapping.set(AuctionStatus.Cancelled, 'Cancelled')

export const activityStatusMapping = new Map()
activityStatusMapping.set(ActivityStatus.Postponed, 'Postponed')
activityStatusMapping.set(ActivityStatus.DateChanged, 'Date changed')

export const auctionLotActivityStatusMapping = new Map()
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.Inactive, 'Inactive')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.Active, 'Active')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.Withdrawn, 'Withdrawn')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.Removed, 'Removed')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.Sold, 'Sold')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.PassedIn, 'Passed In')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.NotSold, 'Not Sold')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.StartDelayed, 'Start Delayed')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.NowSelling, 'Now Selling')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.Paused, 'Paused')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.ResumeDelayed, 'Resume Delayed')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.FinishDelayed, 'Finish Delayed')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.StartDelayPaused, 'Start Delay Paused')
auctionLotActivityStatusMapping.set(AuctionLotActivityStatus.Withdrawn, 'Withdrawn')
